@import "react-phone-input-2/lib/style.css";
.layout {
  max-width: 1440px;
  margin: auto;
}
.layout .content .page {
  position: relative;
  position: relative;
  overflow: hidden;
  border-radius: 13.347px;
  margin: 40px 100px;
  min-height: 670px;
  background: #fff;
  box-shadow: 0px 1.112px 2.224px 0px rgba(28, 28, 28, 0.24), 0px 0px 0px 1.112px rgba(28, 28, 28, 0.08);
  z-index: 0;
}
.layout .content .page .bg_circle-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  -moz-opacity: 0.07;
}
.layout .content .page .bg_circle-wrapper > .circle {
  border-radius: 325px;
  flex: 1 1 30%;
  overflow: visible;
}
.layout .content .page .bg_circle-wrapper .circle-one {
  filter: blur(50px);
  position: absolute;
  width: 550px;
  height: 700px;
  left: 0rem;
  top: 10px;
  border-radius: 100%;
  background: #bde6fc;
}
.layout .content .page .bg_circle-wrapper .circle-two {
  filter: blur(120px);
  position: absolute;
  right: 0.75rem;
  left: 62.75rem;
  top: 30rem;
  width: 200px;
  height: 325px;
  background: #7f00bb;
}
.layout .content .page .bg_circle-wrapper .circle-three {
  filter: blur(180px);
  position: absolute;
  top: 30rem;
  left: 0rem;
  width: 220px;
  height: 225px;
  background: #7f00bb;
}
.layout .content .page .page-content {
  padding: 30px 40px;
}
.layout .content .page .page-content .Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.layout .content .page .page-content .Header .logo {
  max-width: 120px;
  cursor: pointer;
}
.layout .content .page .page-content .Header .buttons {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #1c1c1c;
  border-radius: 8px;
}
.layout .content .page .page-content footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 20px;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  background: #f9fafc;
}
.layout .content .page .page-content footer .footer-btn {
  border-radius: 0 !important;
  padding: 0px 4px !important;
  text-decoration: underline;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .layout {
    max-width: 100%;
    margin: auto;
  }
  .layout .content .page {
    margin: 16px;
    height: 95vh;
  }
  .layout .content .page .bg_circle-wrapper {
    row-gap: 106px;
    margin-top: 52px;
    right: 2.2rem;
    left: 2.2rem;
  }
  .layout .content .page .bg_circle-wrapper > .circle {
    width: 300px;
    height: 316px;
    border-radius: 316px;
    filter: blur(200px);
  }
  .layout .content .page .bg_circle-wrapper .circle-one {
    filter: blur(60px);
    position: absolute;
    width: 100%;
    height: 500px;
    left: 0rem;
    right: 0px;
    top: 30rem;
    bottom: 0px;
    border-radius: 100%;
    background: #bde6fc;
  }
  .layout .content .page .bg_circle-wrapper .circle-two {
    filter: blur(130px);
    position: absolute;
    right: 0rem;
    left: 0rem;
    top: 40rem;
    width: 100%;
    height: 325px;
    background: #7f00bb;
  }
  .layout .content .page .bg_circle-wrapper .circle-three {
    display: none;
  }
  .layout .content .page .page-content {
    padding: 20px;
  }
}
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

button {
  text-transform: unset !important;
}
button:disabled {
  opacity: 0.5;
}

.btn-outlined,
.btn-contained {
  font-size: 16px !important;
  border-radius: 8px !important;
  padding: 10px 42px !important;
  box-shadow: none !important;
}

.btn-text {
  font-size: 14px !important;
  min-width: unset !important;
  min-height: unset !important;
  text-transform: unset !important;
  padding: 0 !important;
  line-height: 1.5 !important;
  border-radius: 0 !important;
}
.btn-text:hover {
  background-color: transparent !important;
}

.ecn-input {
  width: 100%;
  border: none;
}
.ecn-input .MuiInputBase-root {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #212121;
}
.ecn-input .MuiInputBase-root .MuiInputBase-input {
  padding: 11px 16px;
  border-width: 1.5px;
}
.ecn-input .MuiInputBase-root .MuiInputBase-input::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}
.ecn-input .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #0034bb;
  border-width: 1.5px;
}
.ecn-input .MuiInputBase-root:focus, .ecn-input .MuiInputBase-root:active {
  outline: none;
}
.ecn-input .MuiFormHelperText-root {
  margin-left: 2px;
}

.ecn-chip {
  border-radius: 8px !important;
  padding: 4px 10px !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.ecn-checkbox {
  stroke-width: 0.3px !important;
}

.ecn-popper {
  background: #ffffff !important;
  border-radius: 8px !important;
  box-shadow: 0px 12px 40px 0px rgba(33, 33, 33, 0.05) !important;
  border: 1px solid rgba(33, 33, 33, 0.1) !important;
  padding: 16px 0 !important;
  max-width: 400px !important;
  width: 100% !important;
}
.ecn-popper .ecn-popper-item {
  padding: 0 16px !important;
  font-size: 14px !important;
  color: #212121 !important;
  line-height: 24px !important;
}
.ecn-popper .ecn-popper-item:hover {
  background: rgba(33, 33, 33, 0.1) !important;
}

.ecn-snackbar .MuiPaper-root {
  background-color: #ffffff;
  padding: 31px 21px;
  border-radius: 12px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
  align-items: center;
}
.ecn-snackbar .MuiPaper-root .MuiAlert-message {
  color: rgba(33, 33, 33, 0.8);
}
.ecn-snackbar .MuiPaper-root .MuiAlert-message .title-text {
  font-size: 14px;
  line-height: 1;
  color: rgba(33, 33, 33, 0.8);
  font-weight: 600;
  margin-bottom: 0;
}
.ecn-snackbar .MuiPaper-root .MuiAlert-action {
  margin-top: -80px;
  margin-right: -18px;
}
.ecn-snackbar .MuiPaper-root .MuiAlert-action .MuiButtonBase-root .MuiSvgIcon-root {
  height: 16px;
  width: 16px;
}

.mobile-input-container {
  text-align: center;
}
.mobile-input-container .mobile-heading {
  color: #212121;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 25px;
}
.mobile-input-container .mobile-input {
  height: auto;
  border-radius: 8px;
  border: none;
  padding-right: 2px;
  margin-bottom: 55px;
}
.mobile-input-container .mobile-input .form-control {
  width: calc(100% - 74px);
  border-radius: 8px;
  height: 43px;
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  color: #212121;
  border: 1px solid rgba(33, 33, 33, 0.3);
  margin-left: 74px;
  padding-left: 16px;
}
.mobile-input-container .mobile-input .form-control:hover, .mobile-input-container .mobile-input .form-control:focus, .mobile-input-container .mobile-input .form-control:focus-within {
  border-color: rgba(0, 52, 187, 0.7);
}
.mobile-input-container .mobile-input .flag-dropdown {
  border-radius: 8px;
  border-right: none;
  border: 1px solid rgba(33, 33, 33, 0.3);
  background: #ffffff;
}
.mobile-input-container .mobile-input .flag-dropdown.open {
  border-radius: 8px;
}
.mobile-input-container .mobile-input .flag-dropdown.open:hover, .mobile-input-container .mobile-input .flag-dropdown.open:focus, .mobile-input-container .mobile-input .flag-dropdown.open:focus-within {
  border-color: rgba(0, 52, 187, 0.7);
}
.mobile-input-container .mobile-input .flag-dropdown.open .selected-flag {
  border-radius: 8px;
}
.mobile-input-container .mobile-input .flag-dropdown.open .country-list {
  border-radius: 8px;
  text-align: left;
  padding: 11px 10px;
}
.mobile-input-container .mobile-input .flag-dropdown.open .country-list .country {
  padding: 12px 20px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.mobile-input-container .mobile-input .flag-dropdown.open .country-list .country:last-child {
  margin-bottom: 0;
}
.mobile-input-container .mobile-input .flag-dropdown.open .country-list .highlight {
  background: rgba(0, 52, 187, 0.05);
}
.mobile-input-container .mobile-input .flag-dropdown .selected-flag {
  padding: 0 0 0 19px;
  background-color: unset;
  margin-right: 26px;
}
.mobile-input-container .mobile-input .flag-dropdown .selected-flag .flag .arrow {
  border: none;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 6L8 10L12 6" stroke="%23212121" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  top: unset;
  left: 22px;
}
.mobile-input-container .btn-wrapper {
  text-align: right;
  font-size: 16px !important;
}

.mobile-verification-container .mobile-heading {
  color: #212121;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 25px;
  text-align: center;
}
.mobile-verification-container .field .num {
  color: #6b7280;
  margin-bottom: 13px;
  text-align: center;
}
.mobile-verification-container .field .otp-container {
  column-gap: 12px;
  margin-bottom: 8px;
}
.mobile-verification-container .field .otp-container .otp-input {
  height: 48px;
  width: 48px !important;
  border-radius: 4px;
  border: 0;
  background: #f3f4f6;
  font-size: 24px;
}
.mobile-verification-container .field .otp-container .otp-input:focus, .mobile-verification-container .field .otp-container .otp-input:focus-within, .mobile-verification-container .field .otp-container .otp-input:focus-visible {
  border: 0;
  outline: none;
}
.mobile-verification-container .field .loader {
  height: 12px !important;
  width: 12px !important;
}
.mobile-verification-container .field .error {
  font-size: 14px;
  color: #ba0000;
  text-align: left;
}
.mobile-verification-container .field .success {
  font-size: 14px;
  color: #027a48;
  text-align: left;
}
.mobile-verification-container .field .resend {
  font-size: 14px;
  color: #6b7280;
  text-align: left;
  margin-bottom: 90px;
}
.mobile-verification-container .field .resend .time {
  font-weight: 600;
}
.mobile-verification-container .field .resend .resend-btn {
  color: #0034bb;
  font-weight: 600;
  cursor: pointer;
}
.mobile-verification-container .btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-verification-container .btn-wrapper .back-btn {
  margin-inline: 12px;
  color: #6b7280;
  font-size: 16px !important;
}
.mobile-verification-container .btn-wrapper .enable-btn {
  font-size: 16px !important;
}
.mobile-verification-container .btn-wrapper .enable-btn:disabled {
  background: rgba(0, 52, 187, 0.5);
  color: #ffffff;
  opacity: 1;
}

.success-step img {
  position: relative;
  max-width: 163px;
  display: block;
  margin-inline: auto;
  margin-top: -18px;
}
.success-step .success-msg {
  font-weight: 600;
  color: #212121;
  text-align: center;
  max-width: 95%;
  margin: 16.8px auto;
}
.success-step h3 {
  color: #212121;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
}
.success-step .divider {
  height: 1px;
  width: 100%;
  background: rgba(33, 33, 33, 0.1);
  margin-bottom: 40px;
  margin-top: 44px;
}
.success-step .passkey-divider {
  margin-bottom: 18px;
  margin-top: 24px;
}
.success-step .passkey-data .heading {
  font-size: 14px;
  color: #212121;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.success-step .passkey-data .sub {
  font-size: 14px;
  margin: 10px 0;
  color: #212121;
  line-height: 24px;
  letter-spacing: 0.15px;
  max-width: 95%;
}
.success-step .passkey-data .passkey-input {
  margin-bottom: 28px;
}
.success-step .btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.success-step .btn-wrapper .back-btn {
  margin-inline: 12px;
  color: #6b7280;
  font-size: 16px !important;
}
.success-step .btn-wrapper .redirect-btn {
  padding: 10px 32px !important;
}

.setup-auth-app-container {
  text-align: center;
}
.setup-auth-app-container .heading {
  color: #212121;
  line-height: 38px;
  font-weight: 600;
  margin-bottom: 25px;
}
.setup-auth-app-container .sub {
  color: #6b7280;
  margin-bottom: 23px;
}
.setup-auth-app-container .apps-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 47px;
}
.setup-auth-app-container .apps-wrapper .app {
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 8px;
  max-width: 159px;
  overflow: hidden;
  position: relative;
}
.setup-auth-app-container .apps-wrapper .app img {
  width: 158px;
  height: 158px;
  border-radius: 8px;
  cursor: pointer;
}
.setup-auth-app-container .apps-wrapper .app .link-icon {
  position: absolute;
  bottom: 38px;
  right: 8px;
  z-index: 2;
  cursor: pointer;
}
.setup-auth-app-container .apps-wrapper .app .app-name {
  font-size: 10.5px;
  font-weight: 500;
  line-height: 14px;
  color: #212121;
  padding: 8px;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
}
.setup-auth-app-container .btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.setup-auth-app-container .btn-wrapper .alt-btn {
  color: #6b7280;
  max-width: 153px;
  margin-inline: 12px;
}
.setup-auth-app-container .btn-wrapper .next-btn {
  font-size: 16px !important;
}

.auth-app-qr-container {
  text-align: center;
}
.auth-app-qr-container .heading {
  color: #212121;
  line-height: 38px;
  font-weight: 600;
  margin-bottom: 2px;
}
.auth-app-qr-container .qr {
  height: 176px;
  width: 176px;
  margin-bottom: 9px;
}
.auth-app-qr-container .sub {
  color: #6b7280;
  margin-bottom: 23px;
  font-size: 14px;
  margin-inline: 12px;
}
.auth-app-qr-container .copy-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px;
  margin-inline: 16px;
  background: #f3f4f6;
  border-radius: 8px;
  margin-bottom: 35px;
}
.auth-app-qr-container .copy-box .copy-key {
  font-size: 14.5px;
  line-height: 1;
  max-width: 88%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.auth-app-qr-container .copy-box .copy-icon {
  cursor: pointer;
}
.auth-app-qr-container .btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.auth-app-qr-container .btn-wrapper .back-btn {
  font-size: 16px !important;
  color: #6b7280;
  max-width: 153px;
  margin-inline: 12px;
}
.auth-app-qr-container .btn-wrapper .next-btn {
  font-size: 16px !important;
}

.auth-app-verification-container .mobile-heading {
  color: #212121;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 25px;
  text-align: center;
}
.auth-app-verification-container .field .num {
  color: #6b7280;
  margin-bottom: 13px;
  text-align: center;
}
.auth-app-verification-container .field .otp-container {
  column-gap: 12px;
  margin-bottom: 8px;
}
.auth-app-verification-container .field .otp-container .otp-input {
  height: 48px;
  width: 48px !important;
  border-radius: 4px;
  border: 0;
  background: #f3f4f6;
  font-size: 24px;
}
.auth-app-verification-container .field .otp-container .otp-input:focus, .auth-app-verification-container .field .otp-container .otp-input:focus-within, .auth-app-verification-container .field .otp-container .otp-input:focus-visible {
  border: 0;
  outline: none;
}
.auth-app-verification-container .field .loader {
  height: 12px !important;
  width: 12px !important;
}
.auth-app-verification-container .field .error {
  font-size: 14px;
  color: #ba0000;
  text-align: left;
}
.auth-app-verification-container .field .success {
  font-size: 14px;
  color: #027a48;
  text-align: left;
}
.auth-app-verification-container .btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 90px;
}
.auth-app-verification-container .btn-wrapper .back-btn {
  margin-inline: 12px;
  color: #6b7280;
  font-size: 16px !important;
}
.auth-app-verification-container .btn-wrapper .enable-btn {
  font-size: 16px !important;
}
.auth-app-verification-container .btn-wrapper .enable-btn:disabled {
  background: rgba(0, 52, 187, 0.5);
  color: #ffffff;
  opacity: 1;
}

.passkey-setup {
  text-align: center;
}
.passkey-setup .passkey-icon {
  margin-top: 33px;
}
.passkey-setup .heading {
  color: #212121;
  font-weight: 600;
  line-height: 2.2;
}
.passkey-setup .sub {
  color: #6b7280;
  font-size: 15.6px;
  margin-top: 11px;
  margin-inline: -3px;
}
.passkey-setup .support-issue {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 15px 16px;
  text-align: center;
  background: rgba(255, 194, 70, 0.1);
  border-radius: 6px;
  border: 2px dashed #ffc246;
}
.passkey-setup .support-issue .warning-text {
  margin-top: 10px;
  font-size: 14px;
  color: #212121;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.passkey-setup .divider {
  margin: 19px 9px;
  height: 1px;
  background: rgba(33, 33, 33, 0.1);
}
.passkey-setup .support-text {
  color: #6b7280;
  font-size: 15.6px;
  margin-inline: -3px;
}
.passkey-setup .error-text {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 7px;
  color: #ba0000;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-top: 22px;
}
.passkey-setup .btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}
.passkey-setup .btn-wrapper .back-btn {
  margin-inline: 12px;
  color: #6b7280;
  font-size: 16px !important;
}

.signin .container .email-step .card,
.signin .container .password-step .card,
.signin .container .tfa-step .card,
.signin .container .other-step .card {
  background: transparent;
  width: 400px;
  margin: auto;
}
.signin .container .email-step .card h3,
.signin .container .password-step .card h3,
.signin .container .tfa-step .card h3,
.signin .container .other-step .card h3 {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.signin .container .email-step .card .next-button,
.signin .container .password-step .card .next-button,
.signin .container .tfa-step .card .next-button,
.signin .container .other-step .card .next-button {
  text-align: center;
  margin-top: 25px;
}
.signin .container .email-step .card .next-button .signin-next_btn,
.signin .container .password-step .card .next-button .signin-next_btn,
.signin .container .tfa-step .card .next-button .signin-next_btn,
.signin .container .other-step .card .next-button .signin-next_btn {
  margin-bottom: 28px;
  color: rgba(33, 33, 33, 0.8);
  line-height: 26px;
  padding: 2px;
  margin-left: 3px;
  border-radius: 114.939px !important;
  width: 300px;
  margin: auto !important;
  color: #fff;
  box-shadow: 0px 1.161px 2.322px 0px rgba(69, 69, 69, 0.24), 0px 0px 0px 1.161px rgba(71, 71, 71, 0.08);
  transition: background 0.3s ease, color 0.3s ease;
}
.signin .container .email-step .card .next-button .signin-next_btn .right-icon,
.signin .container .password-step .card .next-button .signin-next_btn .right-icon,
.signin .container .tfa-step .card .next-button .signin-next_btn .right-icon,
.signin .container .other-step .card .next-button .signin-next_btn .right-icon {
  margin-top: 2px;
  margin-left: -8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.signin .container .email-step .card .next-button .signin-next_btn:hover,
.signin .container .password-step .card .next-button .signin-next_btn:hover,
.signin .container .tfa-step .card .next-button .signin-next_btn:hover,
.signin .container .other-step .card .next-button .signin-next_btn:hover {
  text-align: center;
}
.signin .container .email-step .card .next-button .signin-next_btn:hover .right-icon,
.signin .container .password-step .card .next-button .signin-next_btn:hover .right-icon,
.signin .container .tfa-step .card .next-button .signin-next_btn:hover .right-icon,
.signin .container .other-step .card .next-button .signin-next_btn:hover .right-icon {
  display: block;
  opacity: 1;
  transform: translateX(5px);
  /* Move the icon 5px to the right */
}
.signin .container .email-step .card .next-button .button-disable,
.signin .container .password-step .card .next-button .button-disable,
.signin .container .tfa-step .card .next-button .button-disable,
.signin .container .other-step .card .next-button .button-disable {
  background: rgb(0, 52, 187);
  color: #fff;
  margin: auto !important;
}
.signin .container .email-step .card .create,
.signin .container .password-step .card .create,
.signin .container .tfa-step .card .create,
.signin .container .other-step .card .create {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  text-align: center;
  margin-top: 20px;
}
.signin .container .email-step .card .create span,
.signin .container .password-step .card .create span,
.signin .container .tfa-step .card .create span,
.signin .container .other-step .card .create span {
  font-weight: 700;
  color: #0034bb;
  cursor: pointer;
}
.signin .container .email-step .card .signup-with-google-and-microsoft,
.signin .container .password-step .card .signup-with-google-and-microsoft,
.signin .container .tfa-step .card .signup-with-google-and-microsoft,
.signin .container .other-step .card .signup-with-google-and-microsoft {
  width: 400px;
  margin: auto;
  margin-top: 30px;
}
.signin .container .email-step .card .signup-with-google-and-microsoft .con,
.signin .container .password-step .card .signup-with-google-and-microsoft .con,
.signin .container .tfa-step .card .signup-with-google-and-microsoft .con,
.signin .container .other-step .card .signup-with-google-and-microsoft .con {
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  margin-bottom: 20px;
}
.signin .container .email-step .card .signup-with-google-and-microsoft .con p,
.signin .container .password-step .card .signup-with-google-and-microsoft .con p,
.signin .container .tfa-step .card .signup-with-google-and-microsoft .con p,
.signin .container .other-step .card .signup-with-google-and-microsoft .con p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  background: #fff;
  margin: auto;
  margin-top: -11px;
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
}
.signin .container .email-step .card .signup-with-google-and-microsoft .google-micro-button,
.signin .container .password-step .card .signup-with-google-and-microsoft .google-micro-button,
.signin .container .tfa-step .card .signup-with-google-and-microsoft .google-micro-button,
.signin .container .other-step .card .signup-with-google-and-microsoft .google-micro-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 25px;
}
.signin .container .email-step .card .signup-with-google-and-microsoft .google-micro-button .google button,
.signin .container .password-step .card .signup-with-google-and-microsoft .google-micro-button .google button,
.signin .container .tfa-step .card .signup-with-google-and-microsoft .google-micro-button .google button,
.signin .container .other-step .card .signup-with-google-and-microsoft .google-micro-button .google button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 10px;
  min-width: 190px;
  background: transparent;
  border: none;
  outline: none;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: rgb(33, 33, 33);
}
.signin .container .email-step .card .signup-with-google-and-microsoft .google-micro-button .google button :hover,
.signin .container .password-step .card .signup-with-google-and-microsoft .google-micro-button .google button :hover,
.signin .container .tfa-step .card .signup-with-google-and-microsoft .google-micro-button .google button :hover,
.signin .container .other-step .card .signup-with-google-and-microsoft .google-micro-button .google button :hover {
  background: rgba(0, 52, 187, 0.05);
}
.signin .container .email-step .card .head,
.signin .container .password-step .card .head,
.signin .container .tfa-step .card .head,
.signin .container .other-step .card .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signin .container .email-step .card .head a,
.signin .container .password-step .card .head a,
.signin .container .tfa-step .card .head a,
.signin .container .other-step .card .head a {
  font-size: 14px;
  text-decoration: none;
  color: #0034bb;
  line-height: 24px;
}
.signin .container .email-step .card .field,
.signin .container .password-step .card .field,
.signin .container .tfa-step .card .field,
.signin .container .other-step .card .field {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.signin .container .email-step .card .field label,
.signin .container .password-step .card .field label,
.signin .container .tfa-step .card .field label,
.signin .container .other-step .card .field label {
  font-size: 14px;
  color: #212121;
  margin-bottom: 2px;
  line-height: 24px;
}
.signin .container .email-step .card .field .MuiInputBase-root,
.signin .container .password-step .card .field .MuiInputBase-root,
.signin .container .tfa-step .card .field .MuiInputBase-root,
.signin .container .other-step .card .field .MuiInputBase-root {
  background: #fff;
}
.signin .container .email-step .card .field ::placeholder,
.signin .container .password-step .card .field ::placeholder,
.signin .container .tfa-step .card .field ::placeholder,
.signin .container .other-step .card .field ::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.8);
}
.signin .container .email-step .card .field .heading,
.signin .container .password-step .card .field .heading,
.signin .container .tfa-step .card .field .heading,
.signin .container .other-step .card .field .heading {
  color: #212121;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
  margin-bottom: 22px;
}
.signin .container .email-step .card .field .sub,
.signin .container .password-step .card .field .sub,
.signin .container .tfa-step .card .field .sub,
.signin .container .other-step .card .field .sub {
  font-size: 14px;
  color: #6b7280;
  text-align: center;
  margin-bottom: 12px;
}
.signin .container .email-step .card .field .otp-container,
.signin .container .password-step .card .field .otp-container,
.signin .container .tfa-step .card .field .otp-container,
.signin .container .other-step .card .field .otp-container {
  column-gap: 12px;
}
.signin .container .email-step .card .field .otp-container .otp-input,
.signin .container .password-step .card .field .otp-container .otp-input,
.signin .container .tfa-step .card .field .otp-container .otp-input,
.signin .container .other-step .card .field .otp-container .otp-input {
  height: 48px;
  width: 48px !important;
  border-radius: 4px;
  border: 0;
  background: #f3f4f6;
}
.signin .container .email-step .card .field .otp-container .otp-input:focus, .signin .container .email-step .card .field .otp-container .otp-input:focus-within, .signin .container .email-step .card .field .otp-container .otp-input:focus-visible,
.signin .container .password-step .card .field .otp-container .otp-input:focus,
.signin .container .password-step .card .field .otp-container .otp-input:focus-within,
.signin .container .password-step .card .field .otp-container .otp-input:focus-visible,
.signin .container .tfa-step .card .field .otp-container .otp-input:focus,
.signin .container .tfa-step .card .field .otp-container .otp-input:focus-within,
.signin .container .tfa-step .card .field .otp-container .otp-input:focus-visible,
.signin .container .other-step .card .field .otp-container .otp-input:focus,
.signin .container .other-step .card .field .otp-container .otp-input:focus-within,
.signin .container .other-step .card .field .otp-container .otp-input:focus-visible {
  border: 0;
  outline: none;
}
.signin .container .email-step .card .field .loader,
.signin .container .password-step .card .field .loader,
.signin .container .tfa-step .card .field .loader,
.signin .container .other-step .card .field .loader {
  margin-top: 5px;
  margin-left: 5px;
  height: 14px !important;
  width: 14px !important;
}
.signin .container .email-step .card .field .error,
.signin .container .password-step .card .field .error,
.signin .container .tfa-step .card .field .error,
.signin .container .other-step .card .field .error {
  font-size: 14px;
  color: #ba0000;
  text-align: left;
  letter-spacing: 0.15px;
  margin-top: 3px;
}
.signin .container .email-step .card .field .success,
.signin .container .password-step .card .field .success,
.signin .container .tfa-step .card .field .success,
.signin .container .other-step .card .field .success {
  font-size: 14px;
  color: #027a48;
  text-align: left;
  letter-spacing: 0.15px;
}
.signin .container .email-step .card .field .resend,
.signin .container .password-step .card .field .resend,
.signin .container .tfa-step .card .field .resend,
.signin .container .other-step .card .field .resend {
  font-size: 14px;
  color: #6b7280;
  text-align: left;
  margin-top: 12px;
}
.signin .container .email-step .card .field .resend .time,
.signin .container .password-step .card .field .resend .time,
.signin .container .tfa-step .card .field .resend .time,
.signin .container .other-step .card .field .resend .time {
  font-weight: 600;
}
.signin .container .email-step .card .field .resend .resend-btn,
.signin .container .password-step .card .field .resend .resend-btn,
.signin .container .tfa-step .card .field .resend .resend-btn,
.signin .container .other-step .card .field .resend .resend-btn {
  color: #0034bb;
  font-weight: 600;
  cursor: pointer;
}
.signin .container .email-step .card .field .line,
.signin .container .password-step .card .field .line,
.signin .container .tfa-step .card .field .line,
.signin .container .other-step .card .field .line {
  margin-top: 0px;
}
.signin .container .email-step .card .action-wrapper,
.signin .container .password-step .card .action-wrapper,
.signin .container .tfa-step .card .action-wrapper,
.signin .container .other-step .card .action-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.signin .container .email-step .card .action-wrapper .other-btn,
.signin .container .password-step .card .action-wrapper .other-btn,
.signin .container .tfa-step .card .action-wrapper .other-btn,
.signin .container .other-step .card .action-wrapper .other-btn {
  font-size: 16px !important;
  color: #6b7280;
  margin-inline: 25px;
}
.signin .container .email-step .card .action-wrapper .signin-next_btn,
.signin .container .password-step .card .action-wrapper .signin-next_btn,
.signin .container .tfa-step .card .action-wrapper .signin-next_btn,
.signin .container .other-step .card .action-wrapper .signin-next_btn {
  font-size: 16px !important;
  font-weight: 500;
}
.signin .container .email-step .card .action-wrapper .signin-btn:disabled,
.signin .container .password-step .card .action-wrapper .signin-btn:disabled,
.signin .container .tfa-step .card .action-wrapper .signin-btn:disabled,
.signin .container .other-step .card .action-wrapper .signin-btn:disabled {
  opacity: 0.5;
  background: #0034bb;
  color: #ffffff;
}
.signin .container .email-step .card .flow-list,
.signin .container .password-step .card .flow-list,
.signin .container .tfa-step .card .flow-list,
.signin .container .other-step .card .flow-list {
  margin-bottom: 10px;
}
.signin .container .email-step .card .flow-list .flow-card,
.signin .container .password-step .card .flow-list .flow-card,
.signin .container .tfa-step .card .flow-list .flow-card,
.signin .container .other-step .card .flow-list .flow-card {
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 13px 14px 13px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 12px;
  cursor: pointer;
}
.signin .container .email-step .card .flow-list .flow-card:last-child,
.signin .container .password-step .card .flow-list .flow-card:last-child,
.signin .container .tfa-step .card .flow-list .flow-card:last-child,
.signin .container .other-step .card .flow-list .flow-card:last-child {
  margin-bottom: 0;
}
.signin .container .email-step .card .flow-list .flow-card .step-icon,
.signin .container .password-step .card .flow-list .flow-card .step-icon,
.signin .container .tfa-step .card .flow-list .flow-card .step-icon,
.signin .container .other-step .card .flow-list .flow-card .step-icon {
  flex-shrink: 0;
}
.signin .container .email-step .card .flow-list .flow-card .text .heading,
.signin .container .password-step .card .flow-list .flow-card .text .heading,
.signin .container .tfa-step .card .flow-list .flow-card .text .heading,
.signin .container .other-step .card .flow-list .flow-card .text .heading {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  line-height: 26px;
}
.signin .container .email-step .card .flow-list .flow-card .text .sub-text,
.signin .container .password-step .card .flow-list .flow-card .text .sub-text,
.signin .container .tfa-step .card .flow-list .flow-card .text .sub-text,
.signin .container .other-step .card .flow-list .flow-card .text .sub-text {
  font-size: 12px;
  color: #212121;
  line-height: 1.4;
}
.signin .container .email-step .passkey,
.signin .container .password-step .passkey,
.signin .container .tfa-step .passkey,
.signin .container .other-step .passkey {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  box-shadow: 0px 12px 40px 0px rgba(33, 33, 33, 0.05);
  padding: 14px 25px;
  margin-top: 18px;
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;
}
.signin .container .email-step .passkey p,
.signin .container .password-step .passkey p,
.signin .container .tfa-step .passkey p,
.signin .container .other-step .passkey p {
  font-weight: 500;
  color: #212121;
}
.signin .container .email-step .signup-text,
.signin .container .password-step .signup-text,
.signin .container .tfa-step .signup-text,
.signin .container .other-step .signup-text {
  color: #212121;
  margin-top: 30px;
  text-align: center;
}
.signin .container .email-step .signup-text span,
.signin .container .password-step .signup-text span,
.signin .container .tfa-step .signup-text span,
.signin .container .other-step .signup-text span {
  color: #0034bb;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .signin .container .email-step .card,
  .signin .container .password-step .card,
  .signin .container .tfa-step .card,
  .signin .container .other-step .card {
    background: transparent;
    width: 100%;
    margin: auto;
  }
  .signin .container .email-step .card h3,
  .signin .container .password-step .card h3,
  .signin .container .tfa-step .card h3,
  .signin .container .other-step .card h3 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 35px;
  }
  .signin .container .email-step .card .signup-with-google-and-microsoft,
  .signin .container .password-step .card .signup-with-google-and-microsoft,
  .signin .container .tfa-step .card .signup-with-google-and-microsoft,
  .signin .container .other-step .card .signup-with-google-and-microsoft {
    width: 100%;
  }
  .signin .container .email-step .card .signup-with-google-and-microsoft .con,
  .signin .container .password-step .card .signup-with-google-and-microsoft .con,
  .signin .container .tfa-step .card .signup-with-google-and-microsoft .con,
  .signin .container .other-step .card .signup-with-google-and-microsoft .con {
    border-top: 1px solid rgba(33, 33, 33, 0.2);
    margin-bottom: 20px;
  }
  .signin .container .email-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button,
  .signin .container .password-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button,
  .signin .container .tfa-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button,
  .signin .container .other-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .signin .container .email-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button .google,
  .signin .container .password-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button .google,
  .signin .container .tfa-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button .google,
  .signin .container .other-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button .google {
    width: 100%;
  }
  .signin .container .email-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button .google button,
  .signin .container .password-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button .google button,
  .signin .container .tfa-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button .google button,
  .signin .container .other-step .card .signup-with-google-and-microsoft .google-micro-button-container .google-micro-button .google button {
    min-width: 120px;
    width: 100%;
    font-weight: 400;
  }
  .signin .container .email-step .card .next-button,
  .signin .container .password-step .card .next-button,
  .signin .container .tfa-step .card .next-button,
  .signin .container .other-step .card .next-button {
    text-align: center;
    margin-top: 25px;
  }
  .signin .container .email-step .card .next-button .signin-next_btn,
  .signin .container .password-step .card .next-button .signin-next_btn,
  .signin .container .tfa-step .card .next-button .signin-next_btn,
  .signin .container .other-step .card .next-button .signin-next_btn {
    width: 100%;
  }
}
.forgot-password {
  display: flex;
  align-items: center;
  justify-content: center;
}
.forgot-password .container .card {
  max-width: 400px;
}
.forgot-password .container .card .email-step h3,
.forgot-password .container .card .password-step h3 {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 26px;
  text-align: center;
}
.forgot-password .container .card .email-step .signin-back_btn,
.forgot-password .container .card .password-step .signin-back_btn {
  font-size: 22px !important;
  font-weight: 600;
  color: #212121;
  margin-bottom: 20px;
  margin-top: 20px;
}
.forgot-password .container .card .email-step .email,
.forgot-password .container .card .password-step .email {
  margin-bottom: 19px;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: rgba(33, 33, 33, 0.8);
}
.forgot-password .container .card .email-step .sub,
.forgot-password .container .card .password-step .sub {
  color: rgba(33, 33, 33, 0.8);
  margin-bottom: 12px;
}
.forgot-password .container .card .email-step .head,
.forgot-password .container .card .password-step .head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.forgot-password .container .card .email-step .field,
.forgot-password .container .card .password-step .field {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.forgot-password .container .card .email-step .field label,
.forgot-password .container .card .password-step .field label {
  font-size: 14px;
  color: #212121;
  margin-bottom: 2px;
  line-height: 24px;
}
.forgot-password .container .card .email-step .field .signin-input,
.forgot-password .container .card .password-step .field .signin-input {
  max-width: 350px;
  width: 350px;
}
.forgot-password .container .card .email-step .field .signin-input .MuiInputBase-root,
.forgot-password .container .card .password-step .field .signin-input .MuiInputBase-root {
  padding-left: 16px;
  font-size: 14px;
  background: #fff;
}
.forgot-password .container .card .email-step .field .signin-input .MuiInputBase-root .MuiInputAdornment-root,
.forgot-password .container .card .password-step .field .signin-input .MuiInputBase-root .MuiInputAdornment-root {
  margin-right: 0;
}
.forgot-password .container .card .email-step .field .success,
.forgot-password .container .card .password-step .field .success {
  font-size: 14px;
  color: #027a48;
  line-height: 24px;
  margin-top: 10px;
  gap: 7px;
}
.forgot-password .container .card .email-step .error-password,
.forgot-password .container .card .password-step .error-password {
  color: #ba0000;
  font-size: 14px;
  line-height: 24px;
}
.forgot-password .container .card .email-step .action-wrapper,
.forgot-password .container .card .password-step .action-wrapper {
  text-align: center;
}
.forgot-password .container .card .email-step .action-wrapper .btn-contained,
.forgot-password .container .card .password-step .action-wrapper .btn-contained {
  border-radius: 114.939px !important;
  box-shadow: 0px 1.161px 2.322px 0px rgba(69, 69, 69, 0.24), 0px 0px 0px 1.161px rgba(78, 66, 66, 0.08);
  font-size: 16px;
  font-weight: 500;
  min-width: 260px;
  margin-top: 35px;
  transition: background 0.3s ease, color 0.3s ease;
}
.forgot-password .container .card .email-step .action-wrapper .btn-contained:disabled,
.forgot-password .container .card .password-step .action-wrapper .btn-contained:disabled {
  opacity: 0.5;
  background: #0034bb;
  color: #ffffff;
}
.forgot-password .container .card .email-step .action-wrapper .btn-contained .right-icon,
.forgot-password .container .card .password-step .action-wrapper .btn-contained .right-icon {
  margin-top: 2px;
  margin-left: -8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.forgot-password .container .card .email-step .action-wrapper .btn-contained:hover,
.forgot-password .container .card .password-step .action-wrapper .btn-contained:hover {
  text-align: center;
}
.forgot-password .container .card .email-step .action-wrapper .btn-contained:hover .right-icon,
.forgot-password .container .card .password-step .action-wrapper .btn-contained:hover .right-icon {
  display: block;
  opacity: 1;
  transform: translateX(5px);
  /* Move the icon 5px to the right */
}
.forgot-password .container .card .email-step .action-wrapper .signin-btn,
.forgot-password .container .card .password-step .action-wrapper .signin-btn {
  margin-bottom: 17px;
}
.forgot-password .container .card .email-step .action-wrapper .signin-btn:disabled,
.forgot-password .container .card .password-step .action-wrapper .signin-btn:disabled {
  opacity: 0.5;
  background: #0034bb;
  color: #ffffff;
}
.forgot-password .container .card .password-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .forgot-password {
    display: unset;
    align-items: unset;
    justify-content: unset;
  }
  .forgot-password .container .card {
    max-width: unset;
    width: 100%;
  }
  .forgot-password .container .card .email-step h3,
  .forgot-password .container .card .password-step h3 {
    font-size: 18px;
    margin-top: 30px;
  }
  .forgot-password .container .card .email-step .signin-back_btn,
  .forgot-password .container .card .password-step .signin-back_btn {
    font-size: 18px !important;
    margin-bottom: 15px;
  }
  .forgot-password .container .card .email-step .field .signin-input,
  .forgot-password .container .card .password-step .field .signin-input {
    max-width: unset;
    width: unset;
    width: 100%;
  }
  .forgot-password .container .card .email-step .action-wrapper,
  .forgot-password .container .card .password-step .action-wrapper {
    text-align: center;
  }
  .forgot-password .container .card .email-step .action-wrapper .btn-contained,
  .forgot-password .container .card .password-step .action-wrapper .btn-contained {
    min-width: 100%;
    margin-top: 5px;
  }
  .success-step {
    margin-top: 70px;
  }
  .success-step h3 {
    font-size: 18px;
  }
  .success-step .redirect-btn {
    border-radius: 114.939px !important;
  }
}
.tfa {
  display: flex;
  justify-content: center;
  min-height: 90vh;
  height: 100%;
  margin-top: 4%;
}
.tfa .container .ecn-logo {
  display: block;
  width: 130px;
  height: auto;
  margin-bottom: 30px;
  margin-inline: auto;
}
.tfa .container .selection-step,
.tfa .container .sms-flow,
.tfa .container .auth-app-flow,
.tfa .container .passkey-flow {
  width: 400px;
}
.tfa .container .selection-step .card,
.tfa .container .sms-flow .card,
.tfa .container .auth-app-flow .card,
.tfa .container .passkey-flow .card {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  box-shadow: 0px 12px 40px 0px rgba(33, 33, 33, 0.05);
  padding: 19px 25px 32px 25px;
}
.tfa .container .selection-step .card h2,
.tfa .container .sms-flow .card h2,
.tfa .container .auth-app-flow .card h2,
.tfa .container .passkey-flow .card h2 {
  font-size: 25px;
  font-weight: 500;
  color: #212121;
  text-align: center;
  margin-bottom: 25px;
}
.tfa .container .selection-step .card .flow-list .flow-card,
.tfa .container .sms-flow .card .flow-list .flow-card,
.tfa .container .auth-app-flow .card .flow-list .flow-card,
.tfa .container .passkey-flow .card .flow-list .flow-card {
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 13px 14px 13px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 12px;
  cursor: pointer;
}
.tfa .container .selection-step .card .flow-list .flow-card:last-child,
.tfa .container .sms-flow .card .flow-list .flow-card:last-child,
.tfa .container .auth-app-flow .card .flow-list .flow-card:last-child,
.tfa .container .passkey-flow .card .flow-list .flow-card:last-child {
  margin-bottom: 0;
}
.tfa .container .selection-step .card .flow-list .flow-card .step-icon,
.tfa .container .sms-flow .card .flow-list .flow-card .step-icon,
.tfa .container .auth-app-flow .card .flow-list .flow-card .step-icon,
.tfa .container .passkey-flow .card .flow-list .flow-card .step-icon {
  flex-shrink: 0;
}
.tfa .container .selection-step .card .flow-list .flow-card .text .heading,
.tfa .container .sms-flow .card .flow-list .flow-card .text .heading,
.tfa .container .auth-app-flow .card .flow-list .flow-card .text .heading,
.tfa .container .passkey-flow .card .flow-list .flow-card .text .heading {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  line-height: 26px;
}
.tfa .container .selection-step .card .flow-list .flow-card .text .sub-text,
.tfa .container .sms-flow .card .flow-list .flow-card .text .sub-text,
.tfa .container .auth-app-flow .card .flow-list .flow-card .text .sub-text,
.tfa .container .passkey-flow .card .flow-list .flow-card .text .sub-text {
  font-size: 12px;
  color: #212121;
  line-height: 1.4;
}
.tfa .container .selection-step .card .flow-list .disabled,
.tfa .container .sms-flow .card .flow-list .disabled,
.tfa .container .auth-app-flow .card .flow-list .disabled,
.tfa .container .passkey-flow .card .flow-list .disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.tfa .container .selection-step .card .action-wrapper,
.tfa .container .sms-flow .card .action-wrapper,
.tfa .container .auth-app-flow .card .action-wrapper,
.tfa .container .passkey-flow .card .action-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;
}
.tfa .container .selection-step .card .action-wrapper .skip-btn,
.tfa .container .sms-flow .card .action-wrapper .skip-btn,
.tfa .container .auth-app-flow .card .action-wrapper .skip-btn,
.tfa .container .passkey-flow .card .action-wrapper .skip-btn {
  color: #6b7280;
  font-size: 16px !important;
  margin-inline: 2px;
}
.tfa .container .selection-step .card .action-wrapper .step-btn,
.tfa .container .sms-flow .card .action-wrapper .step-btn,
.tfa .container .auth-app-flow .card .action-wrapper .step-btn,
.tfa .container .passkey-flow .card .action-wrapper .step-btn {
  font-size: 16px !important;
}
.tfa .container .selection-step .card .progress-bar,
.tfa .container .sms-flow .card .progress-bar,
.tfa .container .auth-app-flow .card .progress-bar,
.tfa .container .passkey-flow .card .progress-bar {
  margin-bottom: 13px;
  margin-top: 0;
}
.tfa .container .selection-step .card .head,
.tfa .container .sms-flow .card .head,
.tfa .container .auth-app-flow .card .head,
.tfa .container .passkey-flow .card .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 33px;
}
.tfa .container .selection-step .card .head .back-btn,
.tfa .container .sms-flow .card .head .back-btn,
.tfa .container .auth-app-flow .card .head .back-btn,
.tfa .container .passkey-flow .card .head .back-btn {
  line-height: 26px;
  color: rgba(33, 33, 33, 0.8);
}
.tfa .container .selection-step .card .head .step-count,
.tfa .container .sms-flow .card .head .step-count,
.tfa .container .auth-app-flow .card .head .step-count,
.tfa .container .passkey-flow .card .head .step-count {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  line-height: 26px;
}
.tfa .container .selection-step .card .passkey-head,
.tfa .container .sms-flow .card .passkey-head,
.tfa .container .auth-app-flow .card .passkey-head,
.tfa .container .passkey-flow .card .passkey-head {
  margin-bottom: 0px;
}

.signup {
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  margin-top: 1rem;
}
.signup .account-selection .title {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
}
.signup .account-selection .select {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
  margin-top: 30px;
}
.signup .account-selection .select .select-card {
  border-radius: 24px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 1px 2px 0px rgba(28, 28, 28, 0.24), 0px 0px 0px 1px rgba(28, 28, 28, 0.08);
  width: 245px;
  padding: 25px;
  padding-top: 0px;
  height: 100%;
  min-height: 353px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.signup .account-selection .select .select-card .images {
  width: 180px;
  height: 170px;
  margin: auto;
}
.signup .account-selection .select .select-card .images img {
  max-width: 100%;
  max-height: 100%;
}
.signup .account-selection .select .select-card .first-image {
  padding-top: 30px;
}
.signup .account-selection .select .select-card h2 {
  font-size: 25px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 10px;
}
.signup .account-selection .select .select-card p {
  font-size: 17px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.8);
  text-align: center;
}
.signup .account-selection .select .select-card:hover {
  box-shadow: 0px 3px 20px 1px rgba(28, 28, 28, 0.1), 0px 0px 0px 1px #0034bb;
}
.signup .password-mobile {
  display: none;
  width: 100%;
}
.signup .branding .old {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
}
.signup .branding .old .old-logo {
  max-width: 86px;
}
.signup .branding .old .sub-head {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.65);
}
.signup .branding .new {
  text-align: center;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 11px;
  padding: 16px 90px;
  margin: 14px 15px;
}
.signup .branding .new .ecn-logo {
  display: block;
  width: 130px;
  height: auto;
  margin-bottom: 0px;
  margin-inline: auto;
}
.signup .branding .new .sub {
  font-size: 12px;
  color: #212121;
}
.signup .mobile-hide-branding {
  display: block;
}
.signup .card {
  width: 600px;
  margin: auto;
}
.signup .card h1 {
  font-size: 20px;
  font-weight: 500;
  color: #212121;
  text-align: center;
  margin-bottom: 20px;
}
.signup .card .field {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 412px;
  margin: auto;
}
.signup .card .field label {
  font-size: 14px;
  color: #212121;
  margin-bottom: 2px;
  line-height: 24px;
  font-weight: 400;
}
.signup .card .field .ecn-input {
  background: #ffffff;
  border-radius: 7px;
  height: 44px;
  margin-bottom: 10px;
}
.signup .card .field .error-state .MuiOutlinedInput-notchedOutline {
  border-color: red;
  border-width: 1.5px;
}
.signup .card .field .email-verified {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #229651;
  margin-top: 3px;
  line-height: 24px;
  margin-bottom: 14px;
}
.signup .card .field .error-password {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #ba0000;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 3px;
}
.signup .card .field .match-password {
  font-size: 14px;
  font-weight: 400;
  color: #229651;
  margin-top: 3px;
  line-height: 24px;
}
.signup .card .mobile-case {
  display: none;
}
.signup .card .login-text {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
  text-align: center;
  margin-top: 15px;
}
.signup .card .login-text span {
  font-weight: 700;
  color: #0034bb;
  cursor: pointer;
}
.signup .card .signup-with-google-and-microsoft {
  width: 412px;
  margin: auto;
  margin-top: 20px;
}
.signup .card .signup-with-google-and-microsoft .con {
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  margin-bottom: 20px;
}
.signup .card .signup-with-google-and-microsoft .con p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  background: #fff;
  margin: auto;
  margin-top: -11px;
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
}
.signup .card .signup-with-google-and-microsoft .google-micro-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 25px;
}
.signup .card .signup-with-google-and-microsoft .google-micro-button .google button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 10px;
  min-width: 196px;
  background: transparent;
  border: none;
  outline: none;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: rgb(33, 33, 33);
}
.signup .card .signup-with-google-and-microsoft .google-micro-button .google button :hover {
  background: rgba(0, 52, 187, 0.05);
}
.signup .card .button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.signup .card .button button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #0034bb;
  color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  padding: 11px 30px;
  min-width: 280px;
  border-radius: 114.939px;
  box-shadow: 0px 1.161px 2.322px 0px rgba(69, 69, 69, 0.24), 0px 0px 0px 1.161px rgba(71, 71, 71, 0.08);
  transition: background 0.3s ease, color 0.3s ease;
}
.signup .card .button button .right-icon {
  margin-top: 2px;
  margin-left: -8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.signup .card .button button:hover {
  text-align: center;
}
.signup .card .button button:hover .right-icon {
  display: block;
  opacity: 1;
  transform: translateX(5px);
  /* Move the icon 5px to the right */
}
.signup .card .switch-account {
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  margin-top: 20px;
  padding-top: 20px;
  text-align: center;
}
.signup .card .switch-account p {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}
.signup .card .switch-account h1 {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 52, 187);
  cursor: pointer;
  width: fit-content;
  text-align: center;
  margin-top: 5px;
  margin: auto;
}
.signup .card .switch-account h1:hover {
  text-decoration: underline;
}
.signup .otp-verification {
  background: transparent;
  width: 400px;
  margin: auto;
}
.signup .otp-verification .title {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
  text-align: center;
  margin-bottom: 14px;
}
.signup .otp-verification .field .sent {
  font-size: 14px;
  font-weight: 400;
  color: #838383;
  margin-bottom: 20px;
  text-align: center;
}
.signup .otp-verification .field .sent span {
  font-weight: 600;
  color: #0034bb;
  cursor: pointer;
}
.signup .otp-verification .field .num {
  font-size: 16px;
  font-weight: 400;
  color: rgb(131, 131, 131);
  text-align: center;
  margin-bottom: 17px;
}
.signup .otp-verification .field .otp-container {
  column-gap: 12px;
  margin-bottom: 8px;
}
.signup .otp-verification .field .otp-container .otp-input {
  height: 44px;
  width: 54px !important;
  border-radius: 4px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px !important;
  font-weight: 500;
}
.signup .otp-verification .field .otp-container .otp-input:focus, .signup .otp-verification .field .otp-container .otp-input:hover, .signup .otp-verification .field .otp-container .otp-input:focus-within, .signup .otp-verification .field .otp-container .otp-input:focus-visible {
  border: 1px solid #0034bb;
  outline: none;
}
.signup .otp-verification .field .otp-container .data-entered {
  border: 1px solid #0034bb;
}
.signup .otp-verification .field .resend {
  font-size: 14px;
  font-weight: 400;
  color: rgb(131, 131, 131);
  margin: 15px 0px;
  text-align: center;
}
.signup .otp-verification .field .resend span {
  color: #0034bb;
  font-weight: 600;
  cursor: pointer;
}
.signup .otp-verification .field .otp-sent {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgb(2, 122, 72);
  margin-top: 0px;
}
.signup .otp-verification .field .button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.signup .otp-verification .field .button button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #0034bb;
  color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  padding: 11px 30px;
  min-width: 280px;
  border-radius: 114.939px;
  box-shadow: 0px 1.161px 2.322px 0px rgba(69, 69, 69, 0.24), 0px 0px 0px 1.161px rgba(71, 71, 71, 0.08);
  transition: background 0.3s ease, color 0.3s ease;
}
.signup .otp-verification .field .button button .right-icon {
  margin-top: 2px;
  margin-left: -8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.signup .otp-verification .field .button button:hover {
  text-align: center;
}
.signup .otp-verification .field .button button:hover .right-icon {
  display: block;
  opacity: 1;
  transform: translateX(5px);
  /* Move the icon 5px to the right */
}
.signup .success-card {
  border-radius: 8px;
  padding: 32px 25px;
  width: 400px;
  border: 1px solid rgba(43, 186, 29, 0.3);
  background: #fff;
  box-shadow: 0px 12px 40px 0px rgba(33, 33, 33, 0.05);
}
.signup .success-card h1 {
  font-size: 25px;
  font-weight: 600;
  color: #212121;
  text-align: center;
}
.signup .success-card .mobile-title {
  display: none;
}
.signup .success-card p {
  font-size: 15px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 15px;
  margin-top: -15px;
}
.signup .success-card .image {
  text-align: center;
  margin: 10px 0px;
}
.signup .success-card .image img {
  width: 170px;
}
.signup .success-card .button {
  text-align: center;
}

.password-desktop {
  display: block;
}
.password-desktop .MuiInputBase-root {
  width: 100% !important;
}

@media only screen and (max-width: 767px) {
  .signup .account-selection {
    margin-top: 50px;
  }
  .signup .account-selection .title {
    font-size: 18px;
    font-weight: 600;
    color: #212121;
  }
  .signup .account-selection .select {
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
  }
  .signup .account-selection .select .select-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    border-radius: 14px;
    width: 100%;
    padding: 16px;
    margin-bottom: 10px;
    min-height: auto;
  }
  .signup .account-selection .select .select-card .images {
    width: 95px;
    height: 95px;
    margin: auto;
    padding-top: 0px;
    margin-bottom: -7px;
  }
  .signup .account-selection .select .select-card .first-image {
    width: 125px;
    height: 105px;
    padding-top: 0px;
    margin-bottom: -20px;
  }
  .signup .account-selection .select .select-card h2 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: -10px;
    text-align: center;
  }
  .signup .account-selection .select .select-card p {
    font-size: 14px;
    text-align: center;
  }
  .signup .account-selection .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  .signup .account-selection .button button {
    min-width: 100%;
  }
  .signup .password-desktop {
    display: none;
    width: 100%;
  }
  .signup .password-mobile {
    display: block;
  }
  .signup .password-mobile .MuiInputBase-root {
    width: 100% !important;
  }
  .signup .card {
    width: 100%;
    margin-top: 50px;
  }
  .signup .card h1 {
    font-size: 18px;
  }
  .signup .card .field {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    margin: auto;
  }
  .signup .card .signup-with-google-and-microsoft {
    width: 100%;
  }
  .signup .card .signup-with-google-and-microsoft .con {
    border-top: 1px solid rgba(33, 33, 33, 0.2);
    margin-bottom: 20px;
  }
  .signup .card .signup-with-google-and-microsoft .google-micro-button .google button {
    min-width: 150px;
    font-weight: 400;
  }
  .signup .card .mobile-case {
    display: block;
    color: rgba(33, 33, 33, 0.5);
    display: block;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
  }
  .signup .card .button button {
    min-width: 100%;
  }
  .signup .card .switch-account {
    border-top: 1px solid rgba(33, 33, 33, 0.2);
    margin-top: 20px;
    padding-top: 20px;
    text-align: center;
  }
  .signup .card .switch-account p {
    font-size: 14px;
    font-weight: 400;
    color: #212121;
  }
  .signup .card .switch-account h1 {
    font-size: 14px;
    font-weight: 500;
    color: rgb(0, 52, 187);
    cursor: pointer;
    margin-top: 5px;
  }
  .signup .card .switch-account h1:hover {
    text-decoration: underline;
  }
  .signup .otp-verification {
    background: transparent;
    width: 100%;
    margin: auto;
    margin-top: 50px;
  }
  .signup .otp-verification .title {
    font-size: 18px;
  }
  .signup .otp-verification .field .sent {
    font-size: 14px;
    font-weight: 400;
    color: #838383;
    margin-bottom: 20px;
    text-align: center;
  }
  .signup .otp-verification .field .sent span {
    font-weight: 600;
    color: #0034bb;
    cursor: pointer;
  }
  .signup .otp-verification .field .num {
    font-size: 16px;
    font-weight: 400;
    color: rgb(131, 131, 131);
    text-align: center;
    margin-bottom: 17px;
  }
  .signup .otp-verification .field .otp-container {
    column-gap: 12px;
    margin-bottom: 8px;
  }
  .signup .otp-verification .field .otp-container .otp-input {
    height: 44px;
    width: 43px !important;
    margin: auto;
  }
  .signup .otp-verification .field .otp-container .data-entered {
    border: 1px solid #0034bb;
  }
  .signup .otp-verification .field .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  .signup .otp-verification .field .button button {
    min-width: 100%;
  }
}
.signup-details {
  display: block;
  align-items: center;
  margin-inline: auto;
}
.signup-details .card {
  width: 400px;
  margin: auto;
}
.signup-details .card .title {
  font-size: 20px;
  font-weight: 500;
  color: #212121;
  text-align: center;
  margin-bottom: 25px;
}
.signup-details .card .row {
  width: 100%;
  margin-bottom: 20px;
}
.signup-details .card .row p {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 5px;
}
.signup-details .card .row .MuiInputBase-root {
  border-radius: 8px;
  height: 44px;
}
.signup-details .card .row .MuiInputBase-root .MuiInputBase-input {
  padding: 9px 14px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.signup-details .card .row .input-text {
  width: 100%;
  background: #ffffff;
  border-radius: 7px;
}
.signup-details .card .row .input-text .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-width: 1.5px;
}
.signup-details .card .row .input-text .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #0034bb;
}
.signup-details .card .row .select-text {
  width: 100%;
  background: #ffffff;
  border-radius: 7px;
}
.signup-details .card .row .select-text .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.signup-details .card .row ::placeholder {
  font-size: 14px;
  font-weight: 400;
  font-family: "Plus Jakarta Sans";
}
.signup-details .card .row .placeholder-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.4);
  font-family: "Plus Jakarta Sans";
}
.signup-details .card .row .iconify {
  color: #121212 !important;
}
.signup-details .card .button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.signup-details .card .button button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #0034bb;
  color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  padding: 11px 30px;
  min-width: 280px;
  border-radius: 114.939px;
  box-shadow: 0px 1.161px 2.322px 0px rgba(69, 69, 69, 0.24), 0px 0px 0px 1.161px rgba(71, 71, 71, 0.08);
  transition: background 0.3s ease, color 0.3s ease;
}
.signup-details .card .button button .right-icon {
  margin-top: 2px;
  margin-left: -8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.signup-details .card .button button:hover {
  text-align: center;
}
.signup-details .card .button button:hover .right-icon {
  display: block;
  opacity: 1;
  transform: translateX(5px); /* Move the icon 5px to the right */
}

@media only screen and (max-width: 767px) {
  .signup-details {
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .signup-details .branding {
    display: none;
  }
  .signup-details .card {
    background: transparent;
    border: unset;
    box-shadow: unset;
    padding: 0px;
    width: 100%;
  }
  .signup-details .card .title {
    font-size: 18px;
    font-weight: 600;
  }
  .signup-details .card .row {
    width: 100%;
    margin-bottom: 15px;
  }
  .signup-details .card .row .input-text {
    width: 100%;
    background: #ffffff;
  }
  .signup-details .card .row .input-text .MuiInputBase-root {
    height: 44px;
  }
  .signup-details .card .row .input-text .MuiInputBase-root .MuiInputBase-input {
    font-size: 16px !important;
  }
  .signup-details .card .row .MuiInputBase-root {
    height: 44px;
  }
  .signup-details .card .button {
    text-align: right;
    margin-top: 50px;
  }
  .signup-details .card .button button {
    width: 100%;
  }
}
.accept-user {
  display: flex;
  justify-content: center;
  min-height: 90vh;
  height: 100%;
  margin-top: 4%;
}
.accept-user .container .card {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  box-shadow: 0px 12px 40px 0px rgba(33, 33, 33, 0.05);
  padding: 32px 25px;
  width: 400px;
  max-width: 400px;
}

.accepted-user {
  margin-top: 70px;
}
.accepted-user .accept-flow {
  width: 400px;
  max-width: 400px;
}
.accepted-user .accept-flow .row .name-field {
  display: flex !important;
  align-items: center !important;
  gap: 20px;
}

.dialog-success .img {
  width: 200px;
  height: 200px;
  margin: auto;
  margin-top: 20px;
}
.dialog-success .img img {
  width: 100%;
  height: 100%;
}
.dialog-success .text {
  text-align: center;
  width: 80%;
  margin: auto;
  padding-bottom: 50px;
  padding-top: 20px;
}
.dialog-success .text h4 {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 20px;
}
.dialog-success .text p {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}
.dialog-success .text p span {
  font-weight: 600;
  color: #0034BB;
}